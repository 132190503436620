<template>
	<div class="lookWai">
		<!--<div>我是好人</div>
		<div>地址信息：{{ url }}</div>-->
		<iframe class="iframe" id="bdIframe" :src="url" style="border:none" :width="searchTableWidth"
:height="searchTableHeight" allowtransparency="true"></iframe>
	</div>
</template>

<script>
	export default {
		name: 'lookWai',
		data() {
			return {
				url: '',
				searchTableHeight: 0,
				searchTableWidth: 0
			}
		},
		methods: {
			widthHeight() {
				this.searchTableHeight = window.innerHeight;
				this.searchTableWidth = window.innerWidth;
			},
		},
		mounted() {
			window.onresize = () => {
				this.widthHeight(); // 自适应高宽度
			};
			this.$nextTick(function() {
				this.widthHeight();
			});
//			window.location.href = this.$route.query.url;
		},
		created() {
//			let arr = this.$route.query.url.split(':');
//			let newUrl = '';
//			if(arr[0]!='https'){
//				for(var i = 0;i<arr.length;i++){
//					if(i==0){
//						newUrl+='https:'
//					}else {
//						newUrl+=arr[i]
//					}
//				}
//			}
            this.url = this.$route.query.url;
            
		}
	}
</script>

<style>
</style>