<template>
	<div class="container">
		<div class="header"><a @click="goback"><img src="../assets/images/back.png" alt=""></a> 用户协议 </div>
		<div class="blian22"></div>
		<div style="width: 95%; margin: 0 auto;" v-html="xyInfo.content"></div>
	</div>
</template>
<script>
	import { userXY } from "@/assets/js/api";
	export default {
		name: "userAgreement",
		data() {
			return {
				xyInfo: {},
			}
		},
		methods: {
			goback: function() {
				this.$router.go(-1)
			},
			//关于我们的信息
			getXY() {
				userXY({}).then(res => {
					if (res.code == 0) {
						console.log(res)
						this.xyInfo = res.userXY;
					}
				});
			}
		},
		mounted() {
			this.getXY()
		}
	};
</script>
