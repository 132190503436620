<template>
	<div class="container">
		<div class="header">
			<a @click="goback"><img src="../assets/images/back.png" alt=""></a> 选择年级和册别</div>
		<div class="blian22"></div>
		<div class="grade-list">
			<h3>选择所在学段</h3>
			<div class="listcont w50">
				<span v-for="(item,index) in learnSectionArr" :class="learnSection == item.tagId ? 'active': ''" :key="index" @click="changeTab(1,item)">
          <img src="../assets/images/checked_on.png" alt="" v-if="learnSection == item.tagId"> 
          <img src="../assets/images/checked.png" alt="" v-else>
          <em>{{item.tagName}}</em>
        </span>
			</div>
		</div>
		<div class="grade-list" v-if="learnSection">
			<h3>选择所在年级</h3>
			<div class="listcont w33">
				<span v-for="(item,index) in gradeArr" :class="grade == item.tagId ? 'active': ''" :key="index" @click="changeTab(2,item)">
          <img src="../assets/images/checked_on.png" alt="" v-if="grade == item.tagId"> 
          <img src="../assets/images/checked.png" alt="" v-else>
          <em>{{item.tagName}}</em>
        </span>
			</div>
		</div>
		<div class="grade-list">
			<h3>选择所在册别</h3>
			<div class="listcont w50">
				<span v-for="(item,index) in volumesArr" :class="volumes == item.tagId ? 'active': ''" :key="index" @click="changeTab(3,item)">
          <img src="../assets/images/checked_on.png" alt="" v-if="volumes == item.tagId"> 
          <img src="../assets/images/checked.png" alt="" v-else>
          <em>{{item.tagName}}</em>
        </span>
			</div>
		</div>
		<button class="commonbtn" @click="pressInfo">完成</button>
	</div>
</template>
<script>
	import { tags } from "@/assets/js/api";
	import { Indicator, Toast } from 'mint-ui'; //加载样式
	export default {
		name: "grade",
		data() {
			return {
				learnSectionArr: [], //学段
				gradeArr: [], //年级
				volumesArr: [], //册别

				grade: '',
				learnSection: '',
				volumes: '',

				gradeName: '',
				learnSectionName: '',
				volumesName: '',
			};
		},
		methods: {
			goback() {
				if(localStorage.getItem('learnSection')) {
					this.$router.go(-1)
				}
			},
			changeTab: function(type, item) {
				if(type == 1) {
					this.learnSection = this.learnSection == item.tagId ? '' : item.tagId;
					this.learnSectionName = this.learnSection == item.tagName ? '' : item.tagName;
					this.getTags(2,item.tagId);
					this.grade = '';
					this.gradeName = '';
					this.volumes = '';
					this.volumesName = '';
				} else if(type == 2) {
					this.grade = this.grade == item.tagId ? '' : item.tagId;
					this.gradeName = this.grade == item.tagName ? '' : item.tagName;
					this.volumes = '';
					this.volumesName = '';
				} else if(type == 3) {
					this.volumes = this.volumes == item.tagId ? '' : item.tagId;
					this.volumesName = this.volumes == item.tagName ? '' : item.tagName;
				}
			},
			getTags(tagType,upperTagId) {
				tags({
					upperTagId:upperTagId,
					tagType: tagType
				}).then(res => {
					if(res.code == 0) {
						Indicator.close()
						if(tagType == 1) {
							this.learnSectionArr = res.tags;
						} else if(tagType == 2) {
							this.gradeArr = res.tags;
						} else if(tagType == 5) {
							this.volumesArr = res.tags;
						}
					}
				});
			},
			//完成
			pressInfo() {
				if(this.learnSection == '') {
					Toast({
						message: '请选择学段',
						position: 'middle',
						duration: 1000
					});
				} else if(this.grade == '') {
					Toast({
						message: '请选择年级',
						position: 'middle',
						duration: 1000
					});
				} else if(this.volumes == '') {
					Toast({
						message: '请选择册别',
						position: 'middle',
						duration: 1000
					});
				} else {
					console.log('年级',this.grade)
					console.log('学段',this.learnSection)
					console.log('侧别',this.volumes)
					
					localStorage.setItem('grade', this.grade) //年级
					localStorage.setItem('learnSection', this.learnSection); //学段
					localStorage.setItem('volumes', this.volumes) //册别
					let obj = {
						gradeName: this.gradeName,
						learnSectionName: this.learnSectionName,
						volumesName: this.volumesName
					}
					console.log(obj)
					localStorage.setItem('selName', JSON.stringify(obj)) //年级
					this.$router.replace({ //核心语句
						path: '/', //跳转的路径
					})
				}

			}
		},
		mounted() {
			Indicator.open()
			//		1:学段， 2：年级， 3：科目， 4：版本， 5：册别 6:试卷类型, 7:地区
			this.getTags(1);
			this.getTags(5);
			if(localStorage.getItem('learnSection')) {
				this.learnSection = localStorage.getItem('learnSection');
				this.grade = localStorage.getItem('grade');
				this.volumes = localStorage.getItem('volumes');
				let obj = JSON.parse(localStorage.getItem('selName'));
				this.gradeName = obj.gradeName;
				this.learnSectionName = obj.learnSectionName;
				this.volumesName = obj.volumesName;
				
				this.getTags(2,localStorage.getItem('learnSection'));
				
				
			}
		}
	};
</script>