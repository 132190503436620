<template>
	<div class="container">
		<div class="header"><a @click="goback"><img src="../assets/images/back.png" alt=""></a> 用户协议 </div>
		<div class="blian22"></div>
		<div style="width: 95%; margin: 0 auto;" v-html="ysInfo.content"></div>
	</div>
</template>
<script>
	import { yszc } from "@/assets/js/api";
	export default {
		name: "privacy",
		data() {
			return {
				ysInfo: {},
			}
		},
		methods: {
			goback: function() {
				this.$router.go(-1)
			},
			//关于我们的信息
			getYS() {
				yszc({}).then(res => {
					if (res.code == 0) {
						console.log(res)
						this.ysInfo = res.yszc;
					}
				});
			}
		},
		mounted() {
			this.getYS()
		}
	};
</script>
